import React from "react"
import { MdOutlineRestartAlt } from "react-icons/md"
const DataSearch = ({
  filter,
  setFilter,
  headerGroups,
  refreshData,
  setRefreshData,
}) => {
  return (
    <div className="sm:overflow-hidden">
      <div className="px-4 py-5 sm:px-6 text-center bg-amber-50 my-3 border border-amber-100 shadow-md rounded-md">
        <label
          htmlFor="filtri-ricerca"
          className="text-base md:text-lg leading-6 font-medium text-gray-900 uppercase flex justify-between flex-col lg:flex-row"
        >
          Filtri ricerca
        </label>
        <div className="grid grid-cols-1 gap-6 items-center">
          <div className="col-span-3 sm:col-span-2">
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-amber-500 bg-super text-sky-900 text-sm">
                Ricerca {""}
              </span>
              <input
                type="text"
                name="filtri-ricerca"
                id="filtri-ricerca"
                className="focus:ring-amber-500 focus:border-amber-50 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-amber-100"
                placeholder=""
                value={filter || ""}
                onChange={e => setFilter(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-1 flex justify-between flex-col lg:flex-row rounded-md">
            {headerGroups.map(headerGroup =>
              headerGroup.headers.map(column =>
                column.Filter ? (
                  <div className="flex my-3" key={column.id}>
                    <label
                      className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-amber-500 bg-super text-sky-900 text-sm"
                      htmlFor={column.id}
                    >
                      {column.render("Header")}
                    </label>
                    {column.render("Filter")}
                  </div>
                ) : null
              )
            )}
          </div>
        </div>
        <button
          className="flex mt-4 items-center px-3 rounded-md border py-2 border-sky-300 bg-blue-500 text-sky-50 text-sm uppercase ease-in-out duration-300"
          onClick={() => setRefreshData(!refreshData)}
        >
          reset <MdOutlineRestartAlt className="ml-2 w-6 h-6" />
        </button>
      </div>
    </div>
  )
}

export default DataSearch
