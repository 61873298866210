import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import Loading from "../../Loading"
import Seo from "../../seo"
import Table from "../Table"
import axios from "axios"
import { getApiToken } from "../../../services/auth"

const getUserDoc = async (userid, type) => {
  const token = await getApiToken()
  const res = await fetch(process.env.CLICK_API_DOCUMENTS + encodeURI(userid) + '?' + new URLSearchParams({
    type: type}), { method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`
    }
  });
  const contentDisposition = res.headers.get('content-disposition');
  console.log(contentDisposition);
  const filename = contentDisposition.match(/filename="(.+)"/)[1]
  const blob = await res.blob();
  const newBlob = new Blob([blob]);

  const blobUrl = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  // clean up Url
  window.URL.revokeObjectURL(blobUrl);
}

const UserList = [
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nome",
    accessor: "given_name",
  },
  {
    Header: "Cognome",
    accessor: "family_name",
  },
  {
    Header: "Telefono",
    accessor: "user_metadata.phone_number",
  },
  {
    Header: "User id",
    accessor: "user_id",
  },
  {
    Header: "Data iscrizione",
    accessor: "created_at",
    Cell: row => (row.value ? moment(row.value).format("DD/MM/YYYY") : "-"),
  },
  {
    Header: "Ultimo login",
    accessor: "last_login",
    Cell: row => (row.value ? moment(row.value).format("DD/MM/YYYY") : "-"),
  },
  {
    Header: "Allegati",
    accessor: "action",
    Cell: row => row.row.original.has_doc ?
    (
      <div className="grid grid-flow-col justify-center gap-1">
        <button
          className="bg-slate-600 text-white py-1 px-2 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
          onClick={e => getUserDoc(row.row.original.user_id, 'front')}
        >
          Fronte
        </button>
        <button
          className="bg-slate-600 text-white py-1 px-2 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
          onClick={e => getUserDoc(row.row.original.user_id, 'back')}
        >
          Retro
        </button>
      </div>
    )
    :
    '-',
  }
]
const ListaUtenti = () => {
  const [userData, setUserData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()

  const fetchUserData = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_USERS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setUserData(response.data)
      setIsLoaded(true)
    }
    catch (error) {
      setError(error)
    }
  }
 
  useEffect(() => {
    fetchUserData()
    // eslint-disable-next-line
  }, [])

  const columns = useMemo(() => UserList, [])
  const data = useMemo(() => userData, [userData])

  return (
    <section className="max-w-full mx-auto px-4 sm:px-6 my-8">
      <Seo title="Lista utenti" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <div className="">
            <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
              Lista utenti
            </h2>
            <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          </div>

          <Table columns={columns} data={data} type={1} />
        </>
      )}
    </section>
  )
}

export default ListaUtenti
