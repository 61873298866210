import React, { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { getApiToken } from "../../services/auth"
import { toast, ToastContainer } from "react-toastify"

const PointsModale = ({ modal, setModal }) => {
  
  const [cardGift, setCardGift] = useState([])
  const cancelButtonRef = useRef(null)
  const [values, setValues] = useState({
    card_id: "",
    points_claimed: "",
    condizioni: "",
  })
  const [error, setError] = useState({
    card_id: "",
    points_claimed: "",
    condizioni: "",
  })
  const handleChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const validateData = values => {
    const errors = {}
    if (!values.card_id.trim()) {
      errors.card_id = "Il numero della carta è obbligatorio"
    }
    if (!values.points_claimed.trim()) {
      errors.points_claimed = "Il numero di punti da riscattare è obbligatorio"
    }
    if (!values.condizioni.trim()) {
      errors.condizioni = "Devi accettare le condizioni"
    }
    return errors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = validateData(values)
    setError(errors)
    const url = process.env.CLICK_API_RISCATTA
    const { card_id, points_claimed, condizioni } = e.target.elements
    const details = {
      card_id: parseInt(card_id.value),
      points_claimed: parseInt(points_claimed.value),
      condizioni: condizioni.value,
    }
    if (Object.keys(errors).length === 0) {
      const token = await getApiToken()
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      })
      const data = response
      const message = await data.text()
      if (data.status === 200) {
        toast.success(message)
        setValues({
          card_id: "",
          points_claimed: "",
          condizioni: "",
        })
        setModal(false)
      } else {
        toast.error(message)
        console.log(error)
        setError({
          card_id: data.error,
        })
      }
    }
  }
  const disabled = !(
    values.card_id.trim() &&
    values.points_claimed.trim() &&
    values.condizioni.valueOf()
  )
  const getCardGift = async () => {
    const url = process.env.CLICK_API_GIFTCARDS
    const token = await getApiToken()
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    const data = await response.json()
    setCardGift(data)
  }
  useEffect(() => {
    getCardGift()
    // eslint-disable-next-line
  }, [])

  const filterCard = cardGift.filter(item =>
    item.points_cut.some(val => val <= values.points_claimed)
  )

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Transition.Root show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="absolute top-0 right-0">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    onClick={() => setModal(false)}
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <Dialog.Title className="text-2xl  text-center py-5 bg-indigo-500 text-white">
                  Richiedi un premio
                </Dialog.Title>

                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit}>
                    <div className="shadow-md overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 ">
                            <label
                              htmlFor="points_claimed"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Punti da riscattare
                            </label>
                            <select
                              id="points_claimed"
                              name="points_claimed"
                              required
                              onChange={handleChange}
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-400 focus:border-amber-400 sm:text-sm"
                            >
                              <option value="">Seleziona</option>
                              <option value={250}>250</option>
                              <option value={500}>500</option>
                              <option value={1000}>1000</option>
                            </select>
                          </div>

                          <div className="col-span-6 ">
                            <label
                              htmlFor="card_id"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Seleziona tipo di card da riscattare
                            </label>
                            <select
                              id="card_id"
                              name="card_id"
                              onChange={handleChange}
                              required
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-400 focus:border-amber-400 sm:text-sm"
                            >
                              <option value="">Seleziona</option>
                              {filterCard.map(card => (
                                <option key={card.id} value={card.id}>
                                  {card.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="flex items-start mt-6">
                          <div className="flex items-center h-5">
                            <input
                              id="condizioni"
                              name="condizioni"
                              type="checkbox"
                              onChange={handleChange}
                              required
                              className="focus:ring-amber-400 h-4 w-4 text-amber-400 border-gray-900 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <p className="text-gray-900">
                              accetto i <a href="https://clicknpay.it/termini-e-condizioni" className="font-medium text-sky-800" target="_new">termini e condizioni</a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        {disabled ? (
                          <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-200  transition duration-150 ease-in-out"
                            disabled
                          >
                            Riscatta
                          </button>
                        ) : (
                          <button
                            onClick={() => setModal(false)}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                          >
                            Riscatta
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}

export default PointsModale
