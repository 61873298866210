import React, { useMemo, useState, useEffect } from "react"
import Table from "../Table"
import Seo from "../../seo"
import Loading from "../../Loading"
import moment from "moment"
import { getApiToken } from "../../../services/auth"
import axios from "axios"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"
const DettaglioRichieste = () => {
  const statusArray = [
    'In attesa',
    'Accettata',
    'Rifiutata'
  ]
  const PointsList = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Utente",
      accessor: "user_email",
    },
    {
      Header: "Carta",
      accessor: "giftcard.name",
    },
    {
      Header: "Punti riscattati",
      accessor: "points_claimed",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: row => statusArray[row.value]
    },
    {
      Header: "Data richiesta",
      accessor: "createdAt",
      Cell: row => (row.value ? moment(row.value).format("DD/MM/YYYY") : "-"),
    },
    {
      Header: "Modificare",
      accessor: "action",
      Cell: row => (
        <div className="flex justify-center">
          <button
            className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
            onClick={e => handleEdit(row.row.original)}
          >
            Modifica
          </button>
        </div>
      ),
    },
  ]
  const [dettaglio, setDettaglio] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [edit, setEdit] = useState([])
  const [modalEdit, setModalEdit] = useState(false)

  const [cardCode, setCardCode] = useState([])
  const [values, setValues] = useState({
    status: "",
    card_code: "",
    note_user: "",
    note_admin: "",
    type: "",
    card_expiration: "",
  })
  const handleEdit = row => {
    setEdit(row)
    setModalEdit(true)
    setCardCode(JSON.parse(row.card_code))
  }

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleNewChange = e => {
    setCardCode(
      [{ ...cardCode[0], [e.target.name]: e.target.value }].filter(
        item =>
          item.code !== "" &&
          item.link !== "" &&
          item.pin !== "" &&
          item.password !== ""
      )
    )
  }
  const fetchDettaglio = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_ADMIN_REQUESTS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setDettaglio(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }
  useEffect(() => {
    fetchDettaglio()
    // eslint-disable-next-line
  }, [])
  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const dataToSend = {
      status: parseInt(e.target.status.value),
      card_code: JSON.stringify(cardCode),
      note_user: e.target.note_user.value,
      note_admin: e.target.note_admin.value,
      type: parseInt(e.target.type.value),
      card_expiration: e.target.card_expiration.value,
    }
    const url = `${process.env.CLICK_API_ADMIN_REQUESTS}/${edit.id}`
    try {
      await axios({
        url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataToSend,
      }).then(() => {
        setModalEdit(false)
        fetchDettaglio()
        setCardCode([
          { code: "", pin: "" },
          { link: "", password: "" },
          { code: "" },
        ])
        toast.success("Richiesta aggiornata")
      })
    } catch (error) {
      setError(error)
      toast.error("Errore")
    }
  }

  const columns = useMemo(() => PointsList, []) // eslint-disable-line
  const data = useMemo(() => dettaglio, [dettaglio])

  return (
    <section className="max-w-full mx-auto px-4 sm:px-6 my-8">
      <Seo title="Dettaglio Richieste" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <div className="">
            <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
              Dettaglio Richieste
            </h2>
            <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          </div>
          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica Richiesta"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <label
                          htmlFor="status"
                          className="form-input-label"
                        >
                          Status
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                          <select
                            name="status"
                            id="status"
                            className="form-input"
                            onChange={handleChange}
                            defaultValue={edit.status}
                          >
                            <option value="">Seleziona uno stato</option>
                            <option value={0}>In attesa</option>
                            <option value={1}>Accettata</option>
                            <option value={2}>Rifiutata</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-4">
                        <label
                          htmlFor="type"
                          className="text-sm font-medium leading-5 text-gray-700"
                        >
                          <input
                            type="radio"
                            name="type"
                            value={0}
                            onChange={handleChange}
                            defaultChecked={edit.type === 0}
                            className="mr-2 h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                          />
                          Solo codice
                        </label>
                      </div>
                      <div className="mt-1 rounded-md shadow-sm col-span-8">
                        <input
                          name="code"
                          id="code"
                          type="text"
                          className="form-input"
                          onChange={handleNewChange}
                          defaultValue={
                            edit.type === 0 && edit.card_code
                              ? JSON.parse(edit.card_code).map(
                                  item => item.code
                                )
                              : ""
                          }
                        />
                      </div>

                      <hr className="col-span-12" />

                      <div className="col-span-4">
                        <label
                          htmlFor="type"
                          className="text-sm font-medium leading-5 text-gray-700"
                        >
                          <input
                            type="radio"
                            name="type"
                            value={1}
                            onChange={handleChange}
                            defaultChecked={edit.type === 1}
                            className="mr-2 h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                          />
                          Codice con PIN
                        </label>
                      </div>
                      <div className="mt-1 rounded-md shadow-sm col-span-4">
                        <label
                          htmlFor="code"
                          className="text-xs font-medium leading-5 text-gray-700"
                        >
                          Code
                          <input
                            name="code"
                            id="code"
                            type="text"
                            className="form-input"
                            onChange={handleNewChange}
                            defaultValue={
                              edit.type === 1 && edit.card_code
                                ? JSON.parse(edit.card_code).map(
                                    item => item.code
                                  )
                                : ""
                            }
                          />
                        </label>
                      </div>
                      <div className="mt-1 rounded-md shadow-sm col-span-4">
                        <label
                          htmlFor="pin"
                          className="text-xs font-medium leading-5 text-gray-700"
                        >
                          PIN
                          <input
                            name="pin"
                            id="pin"
                            type="text"
                            className="form-input"
                            onChange={handleNewChange}
                            defaultValue={
                              edit.type === 1 && edit.card_code
                                ? JSON.parse(edit.card_code).map(
                                    item => item.pin
                                  )
                                : ""
                            }
                          />
                        </label>
                      </div>

                      <hr className="col-span-12" />

                      <div className="col-span-4">
                        <label
                          htmlFor="type"
                          className="text-sm font-medium leading-5 text-gray-700"
                        >
                          <input
                            type="radio"
                            name="type"
                            value={2}
                            onChange={handleChange}
                            defaultChecked={edit.type === 2}
                            className="mr-2 h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                          />
                          File allegato <a className="font-medium text-sky-800" href="https://flexipec-my.sharepoint.com/personal/admin_flexipec_onmicrosoft_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fadmin%5Fflexipec%5Fonmicrosoft%5Fcom%2FDocuments%2FPremi" target="_new">(OneDrive)</a>
                        </label>
                      </div>
                      <div className="mt-1 rounded-md shadow-sm col-span-4">
                        <label
                          htmlFor="link"
                          className="text-xs font-medium leading-5 text-gray-700"
                        >
                          Link
                          <input
                            name="link"
                            id="link"
                            type="text"
                            className="form-input"
                            onChange={handleNewChange}
                            defaultValue={
                              edit.type === 2 && edit.card_code
                                ? JSON.parse(edit.card_code).map(
                                    item => item.link
                                  )
                                : ""
                            }
                          />
                        </label>
                      </div>
                      <div className="mt-1 rounded-md shadow-sm col-span-4">
                        <label
                          htmlFor="password"
                          className="text-xs font-medium leading-5 text-gray-700"
                        >
                          Password
                          <input
                            name="password"
                            id="password"
                            type="text"
                            className="form-input"
                            onChange={handleNewChange}
                            defaultValue={
                              edit.type === 2 && edit.card_code
                                ? JSON.parse(edit.card_code).map(
                                    item => item.password
                                  )
                                : ""
                            }
                          />
                        </label>
                      </div>

                      <hr className="col-span-12" />

                      <div className="col-span-12">
                        <label
                          htmlFor="card_expiration"
                          className="text-sm font-medium leading-5 text-gray-700"
                        >
                          Scadenza
                          <input
                            name="card_expiration"
                            id="card_expiration"
                            type="date"
                            className="form-input"
                            onChange={handleNewChange}
                            defaultValue={edit.card_expiration}
                          />
                        </label>
                      </div>

                      <div className="col-span-12">
                        <label
                          htmlFor="note_user"
                          className="form-input-label"
                          onChange={handleChange}
                          defaultValue={edit.note_user}
                        >
                          Note utente
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                          <textarea
                            name="note_user"
                            id="note_user"
                            className="form-input"
                            onChange={handleChange}
                            defaultValue={edit.note_user}
                          />
                        </div>
                      </div>

                      <div className="col-span-12">
                        <label
                          htmlFor="note_admin"
                          className="form-input-label"
                          onChange={handleChange}
                          defaultValue={edit.note_admin}
                        >
                          Note amministratore
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                          <textarea
                            name="note_admin"
                            id="note_admin"
                            className="form-input"
                            onChange={handleChange}
                            defaultValue={edit.note_admin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Modifica
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
          <Table columns={columns} data={data} />
        </>
      )}
    </section>
  )
}

export default DettaglioRichieste
