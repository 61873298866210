import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { getApiToken } from "../../../services/auth"
import Loading from "../../Loading"
import Seo from "../../seo"
import Table from "../Table"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"
import InsertModale from "../InsertModale"
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Prodotti = () => {
  const [prodotti, setProdotti] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [modalEdit, setModalEdit] = useState(false)
  const [edit, setEdit] = useState([])
  const [modal, setModal] = useState(false)
  const [editorState, setEditorState] = useState([
  EditorState.createEmpty()
  ]);
  const [formValues, setFormValues] = useState([{ label: "", text: "" }])
  let handleNewChange = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i][e.target.name] = e.target.value
    setFormValues(newFormValues)
  }
  let handleNewChangeRTE = (i, e) => {
    let editorStates = [...editorState];
    editorStates[i] = e;
    setEditorState(editorStates);
    console.log(editorStates);
    let newFormValues = [...formValues]
    newFormValues[i]["text"] = draftToHtml(convertToRaw(e.getCurrentContent()));
    console.log(newFormValues[i]["text"]);
    setFormValues(newFormValues)
  }
  let addFormFields = () => {
    setFormValues([...formValues, { label: "", text: "" }]);
    setEditorState([...editorState,
  EditorState.createEmpty()
  ]);
  }
  const openInsertModal = () => {
    setFormValues([{ label: "", text: "" }]);
    setEditorState([
      EditorState.createEmpty()
      ]);
    setModal(true);
  }
  let removeFormFields = i => {
    let newFormValues = [...formValues]
    let editorStates = [...editorState]
    editorStates.splice(i, 1)
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
    setEditorState(editorStates)
  }
  const fetchProdotti = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_PRODUCTS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { type: "form" },
      })
      setProdotti(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    fetchProdotti()
    // eslint-disable-next-line
  }, [])

  const { products, categories, programs } = prodotti
  const [values, setValues] = useState({
    name: "",
    display: "",
    display_name: "",
    prod_definition: "",
    category_id: "",
    program_id: "",
    expected_value: "",
    reflink: "",
    json_metadata: "",
  })
  const toolbarSettings = {
    options: ['inline', 'list', 'textAlign', 'link', 'history']
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const token = await getApiToken()

    const url = process.env.CLICK_API_PRODUCTS
    try {
      await axios({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          name: values.name,
          display: parseInt(values.display),
          display_name: values.display_name,
          prod_definition: values.prod_definition,
          category_id: parseInt(values.category_id),
          program_id: parseInt(values.program_id),
          expected_value: parseInt(values.expected_value),
          reflink: values.reflink,
          json_metadata: formValues,
        },
      })
      setModal(false)
      toast.success("Prodotto inserito con successo")
      fetchProdotti()
      setFormValues([{ label: "", text: "" }])
    } catch (error) {
      setModal(false)
      toast.error("Errore nell'inserimento del prodotto")
    }
  }
  const handleMetadata = metadata => {
    var editorStates = [];
    metadata && metadata.length ? setFormValues(metadata) : setFormValues([{label: "", text: ""}]);
    if(!(metadata && metadata.length)){
      setEditorState([EditorState.createEmpty()]);
      return;
    }
    for(const [i, v] of metadata.entries()){
      const blocksFromHTML = convertFromHTML(v.text);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      editorStates[i] = EditorState.createWithContent(state);
    }
    setEditorState(editorStates)
  }
  const handleChange = e => {
    e.preventDefault()
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = `${process.env.CLICK_API_PRODUCTS}/${edit.id}`
    try {
      await axios({
        url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          name: e.target.name.value,
          display: parseInt(e.target.display.value),
          display_name: e.target.display_name.value,
          prod_definition: e.target.prod_definition.value,
          category_id: parseInt(e.target.category_id.value),
          program_id: parseInt(e.target.program_id.value),
          expected_value: parseInt(e.target.expected_value.value),
          reflink: e.target.reflink.value,
          json_metadata: formValues,
        },
      })
      setModalEdit(false)
      toast.success("Prodotto aggiornato con successo")
      fetchProdotti()
      setFormValues([{ label: "", text: "" }])
    } catch (error) {
      setModalEdit(false)
      toast.error("Errore nell'aggiornamento del prodotto")
    }
  }

  /* =========== DATA =========*/

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Categoria",
        accessor: "category.name",
      },
      {
        Header: "Programma",
        accessor: "program.name",
      },
      {
        Header: "Attivo",
        accessor: "program.enabled",
      },

      {
        Header: "Piattaforma",
        accessor: "program.platform.name",
      },
      {
        Header: "Modificare",
        accessor: "action",
        Cell: row => (
          <div className="flex justify-center">
            <button
              className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
              onClick={e => handleEdit(row.row.original)}
            >
              Modifica
            </button>
          </div>
        ),
      },
    ],
    [] // eslint-disable-line
  ) 
  const data = useMemo(() => products, [products])
  const handleEdit = row => {
    setEdit(row)
    handleMetadata(row.json_metadata)
    setModalEdit(true)
  }
  return (
    <section className="max-w-screen-2xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Prodotti" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
            Lista prodotti
          </h2>
          <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          <div className="">
            <button className="super-btn" onClick={openInsertModal}>
              Inserisci
            </button>
            <InsertModale
              modal={modal}
              setModal={setModal}
              title={"Inserisci Dati"}
            >
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="shadow-md overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="name"
                          >
                            Nome prodotto
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Nome"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="display"
                          >
                            Mostra
                          </label>
                          <select
                            className="form-input"
                            name="display"
                            id="display"
                            onChange={handleChange}
                          >
                            <option value="">Seleziona</option>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                          </select>
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="display_name"
                          >
                            Nome mostrato
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="display_name"
                            id="display_name"
                            placeholder="Nome mostrato"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="prod_definition"
                          >
                            Definizione
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="prod_definition"
                            id="prod_definition"
                            placeholder="Definizione"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="category_id"
                          >
                            Categoria
                          </label>
                          <select
                            className="form-input"
                            name="category_id"
                            id="category_id"
                            onChange={handleChange}
                          >
                            <option value="">Seleziona</option>
                            {categories.map(cat => (
                              <option key={cat.id} value={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="program_id"
                          >
                            Programma
                          </label>
                          <select
                            className="form-input"
                            name="program_id"
                            id="program_id"
                            onChange={handleChange}
                          >
                            <option value="">Seleziona</option>
                            {programs.map(pro => (
                              <option key={pro.id} value={pro.id}>
                                {pro.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="expected_value"
                          >
                            Valore atteso
                          </label>
                          <input
                            className="form-input"
                            type="number"
                            name="expected_value"
                            id="expected_value"
                            placeholder="Valore atteso"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="reflink"
                          >
                            Reflink
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="reflink"
                            id="reflink"
                            placeholder="Reflink"
                            onChange={handleChange}
                          />
                        </div>
                        <hr className="col-span-6" />
                        <h4 className="col-span-6 flex justify-center uppercase font-medium text-slate-800">
                          Dati aggiuntivi
                        </h4>
                        {formValues.map((item, index) => (
                          <div className="col-span-4 contents" key={index}>
                            <div className="col-span-3">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="label"
                              >
                                Label
                              </label>
                              <input
                                className="form-input"
                                type="text"
                                name="label"
                                id="label"
                                onChange={e => handleNewChange(index, e)}
                              />
                            </div>
                            <div className="col-span-3">
                              <label
                                className="block text-sm font-medium text-gray-700"
                                htmlFor="text"
                              >
                                Text
                              </label>
                              <Editor
                                name="text"
                                id="text"
                                editorState={editorState[index]}
                                wrapperClassName="form-input"
                                editorClassName="p-2"
                                toolbar={toolbarSettings}
                                onEditorStateChange={e => handleNewChangeRTE(index, e)} />
                            </div>
                            {index ? (
                              <button
                                className="transition-colors duration-200 col-span-6 px-2 py-1 w-1/6 rounded-md text-sm bg-red-500 text-white hover:text-gray-100 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
                                type="button"
                                onClick={() => removeFormFields(index)}
                              >
                                Rimuovi campo
                              </button>
                            ) : null}
                          </div>
                        ))}
                        <div className="col-span-2 ">
                          <button
                            className=" flex justify-center py-1 px-3 border border-transparent uppercase rounded-md text-white bg-green-600 hover:bg-green-700 transition-colors duration-200"
                            type="button"
                            onClick={() => addFormFields()}
                          >
                            Aggiungi campo
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        onClick={() => setModal(false)}
                        type="submit"
                        className="form-insert-button"
                      >
                        Aggiungi
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </InsertModale>
          </div>
          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica Dati"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 ">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="name"
                        >
                          Nome
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Nome"
                          defaultValue={edit.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="display"
                        >
                          Mostra
                        </label>
                        <select
                          className="form-input"
                          name="display"
                          id="display"
                          defaultValue={edit.display}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="display_name"
                        >
                          Nome mostrato
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          name="display_name"
                          id="display_name"
                          placeholder="Nome mostrato"
                          defaultValue={edit.display_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="prod_definition"
                        >
                          Definizione
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          name="prod_definition"
                          id="prod_definition"
                          placeholder="Definizione"
                          defaultValue={edit.prod_definition}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="category_id"
                        >
                          Categoria
                        </label>
                        <select
                          className="form-input"
                          name="category_id"
                          id="category_id"
                          defaultValue={edit.category_id}
                          onChange={handleChange}
                        >
                          <option value="">Seleziona</option>
                          {categories.map(cat => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="program_id"
                        >
                          Programma
                        </label>
                        <select
                          className="form-input"
                          name="program_id"
                          id="program_id"
                          defaultValue={edit.program_id}
                          onChange={handleChange}
                        >
                          <option value="">Seleziona</option>
                          {programs.map(pro => (
                            <option key={pro.id} value={pro.id}>
                              {pro.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="expected_value"
                        >
                          Valore atteso
                        </label>
                        <input
                          className="form-input"
                          type="number"
                          name="expected_value"
                          id="expected_value"
                          placeholder="Valore atteso"
                          defaultValue={edit.expected_value}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="reflink"
                        >
                          Reflink
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          name="reflink"
                          id="reflink"
                          placeholder="Reflink"
                          defaultValue={edit.reflink}
                          onChange={handleChange}
                        />
                      </div>
                      <hr className="col-span-6" />
                      <h4 className="col-span-6 flex justify-center uppercase font-medium text-slate-800">
                        Dati aggiuntivi
                      </h4>
                      {formValues.map((item, index) => (
                        <div className="col-span-4 contents" key={index}>
                          <div className="col-span-3">
                            <label
                              className="block text-sm font-medium text-gray-700"
                              htmlFor="label"
                            >
                              Label
                            </label>
                            <input
                              className="form-input"
                              type="text"
                              name="label"
                              id="label"
                              value={item.label}
                              onChange={e => handleNewChange(index, e)}
                            />
                          </div>
                          <div className="col-span-3">
                            <label
                              className="block text-sm font-medium text-gray-700"
                              htmlFor="text"
                            >
                              Text
                            </label>
                            <Editor
                                name="text"
                                id="text"
                                editorState={editorState[index]}
                                wrapperClassName="form-input"
                                editorClassName="p-2"
                                toolbar={toolbarSettings}
                                onEditorStateChange={e => handleNewChangeRTE(index, e)} />
                          </div>
                          {index ? (
                            <button
                              className="transition-colors duration-200 col-span-6 px-2 py-1 w-1/6 rounded-md text-sm bg-red-500 text-white hover:text-gray-100 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
                              type="button"
                              onClick={() => removeFormFields(index)}
                            >
                              Rimuovi campo
                            </button>
                          ) : null}
                        </div>
                      ))}
                      <div className="col-span-2 ">
                        <button
                          className=" flex justify-center py-1 px-3 border border-transparent uppercase rounded-md text-white bg-green-600 hover:bg-green-700 transition-colors duration-200"
                          type="button"
                          onClick={() => addFormFields()}
                        >
                          Aggiungi campo
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Modifica
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
          <Table columns={columns} data={data} />
        </>
      )}
    </section>
  )
}

export default Prodotti
