import React from "react"
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table"
import GlobalSearch from "./GlobalSearch"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
export function StatusPill({ value }) {
  const status = value ? value : "unknown"

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide rounded-full shadow-sm",
        status.startsWith("Si") ? "bg-green-100 text-green-700" : null,
        status.startsWith("No") ? "bg-red-100 text-red-500" : null
      )}
    >
      {status}
    </span>
  )
}

const Table = ({ columns, data, toggleModal, initialState = {}, type = 0 }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state
  return (
    <section>
      <GlobalSearch
        filter={globalFilter}
        setFilter={setGlobalFilter}
        toggleModal={toggleModal}
      />

      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow lg:shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-200"
                {...getTableProps}
              >
                {/* Table header */}
                <thead className="bg-gray-100">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          scope="col"
                          className="group border-x px-3 py-2 md:py-4 text-center text-xs md:text-sm font-normal md:font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaSortDown className="w-4 h-4 text-gray-400 inline-flex" />
                              ) : (
                                <FaSortUp className="w-4 h-4 text-gray-400 inline-flex" />
                              )
                            ) : (
                              <FaSort className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 inline-flex" />
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {/* Table body */}
                <tbody
                  className="bg-white divide-y divide-gray-200 text-center"
                  {...getTableBodyProps}
                >
                  {(page.length > 0 &&
                    page.map(row => {
                      prepareRow(row)
                      return (
                        <tr
                          className="border-b odd:bg-white even:bg-gray-50 hover:bg-slate-200"
                          {...row.getRowProps()}
                        >
                          {row.cells.map(cell => {
                            return (
                              <td
                                className={type === 1 ? "px-3 border-x py-2 whitespace-nowrap text-xs md:text-base font-medium text-gray-900 leading-5" : "px-6 border-x py-4 whitespace-nowrap text-xs md:text-base font-medium text-gray-900 leading-5"}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })) || (
                    <tr className="border-b odd:bg-white even:bg-gray-50 hover:bg-slate-200">
                      <td
                        className="px-6 py-4 whitespace-nowrap text-red-500 leading-5  uppercase text-center"
                        colSpan="8"
                      >
                        <span>nessun risultato trovato</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="flex justify-between items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              <button
                className={`${canPreviousPage ? "" : "opacity-50"}`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <li className="list-none">
                  <span className="flex items-center justify-center w-10 h-10 text-slate-600 transition-colors duration-150 rrounded-l-lg focus:shadow-outline hover:bg-slate-100">
                    <FaChevronLeft className="h-7" />
                  </span>
                </li>
              </button>
              <span>
                Pagina{" "}
                <strong>
                  {pageIndex + 1} di {pageOptions.length}
                </strong>{" "}
              </span>
              <select
                className="  pl-3 pr-7 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm sm:leading-5"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Mostra {pageSize}
                  </option>
                ))}
              </select>
              <button
                className={`${canNextPage ? "" : "opacity-50"}`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <li className="list-none">
                  <span className="flex items-center justify-center w-10 h-10 text-slate-600 transition-colors duration-150 bg-white rounded-r-lg focus:shadow-outline hover:bg-slate-100">
                    <FaChevronRight className="h-7" />
                  </span>
                </li>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Table
