import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const ChartPro = ({ userPoint }) => {
  const vendite = userPoint.reduce((chart, { product: { name }, data }) => {
    if (!chart[name]) chart[name] = []
    chart[name].push(data)
    return chart
  }, {})
  
  const data = {
    labels: Object.keys(vendite),
    datasets: [
      {
        label: "",
        data: Object.values(vendite).map(v => v.length),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          '#a5b4fc',
          '#67e8f9',
          '#fcd34d',
          '#d6d3d1',
          '#84cc16',
          '#0ea5e9'
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          '#818cf8',
          '#22d3ee',
          '#fbbf24',
          '#a8a29e',
          '#65a30d',
          '#0284c7'
        ],
        borderWidth: 1,
      },
    ],
  }
  return (
    <div className="chart">
      <Pie data={data} />
    </div>
  )
}
 

export default ChartPro

  /* const totalPoints = userPoint.reduce((acc, { points_user, claimed }) => {
    if (claimed === 0) acc += points_user
    return acc
  }, 0)
 */