import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { getApiToken } from "../../../services/auth"
import Loading from "../../Loading"
import Seo from "../../seo"
import Table from "../Table"
import InsertModale from "../InsertModale"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"

const Formule = () => {
  
  const [formule, setFormule] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [modalEdit, setModalEdit] = useState(false)
  const [edit, setEdit] = useState([])
  const [modal, setModal] = useState(false)

  const getFormule = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_FORMULE
    try {
      const response = await axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { type: "form" },
      })
      setFormule(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }

  const { formulas, products: productsUnf } = formule
  const products = () => {
    const hasFormula = formulas.reduce((p, c) => {
      let r = {...p};
      r[c.product_id] = true;
      return r;
    }, [])
    return productsUnf.map((prod) => {
      const result = hasFormula[prod.id] ? {...prod, name: prod.name + ' - O'} : {...prod, name: prod.name + ' - X'}
      return result;
    })
  }

  useEffect(() => {
    getFormule()
    // eslint-disable-next-line
  }, [])
  const [values, setValues] = useState({
    product_id: "",
    formula: "",
    verbose: "",
    type: "",
  })
  const handleChange = e => {
    e.preventDefault()
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = process.env.CLICK_API_FORMULE
    try {
      await axios({
        url: url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          product_id: parseInt(values.product_id),
          formula: values.formula,
          verbose: values.verbose,
          type: parseInt(values.type),
        },
      })
      getFormule()
      setModal(false)
      toast.success("Formule inserito con successo")
    } catch (error) {
      toast.error("Errore nell'inserimento della formule")
    }
  }

  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = `${process.env.CLICK_API_FORMULE}/${edit.id}`
    try {
      await axios({
        url: url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          product_id: parseInt(e.target.product_id.value),
          formula: e.target.formula.value,
          verbose: e.target.verbose.value,
          type: parseInt(e.target.type.value),
        },
      })
      getFormule()
      setModalEdit(false)
      toast.success("Formule aggiornato con successo")
    } catch (error) {
      toast.error("Errore nell'aggiornamento della formule")
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Prodotto",
        accessor: "product.name",
      },
      {
        Header: "Formula",
        accessor: "formula",
      },
      {
        Header: "Formula visibile",
        accessor: "verbose",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Modificare",
        accessor: "action",
        Cell: row => (
          <div className="flex justify-center">
            <button
              className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
              onClick={e => handleEdit(row.row.original)}
            >
              Modifica
            </button>
          </div>
        ),
      },
    ],
    []
  )
  const data = useMemo(() => formulas, [formulas])
  const handleEdit = row => {
    setEdit(row)
    setModalEdit(true)
  }
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Formule" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
            Lista formule
          </h2>
          <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <div>
            <button className="super-btn" onClick={() => setModal(true)}>
              Inserisci
            </button>
            <InsertModale
              modal={modal}
              setModal={setModal}
              title={"Inserisci Dati"}
            >
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="shadow-md overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3">
                          <label
                            htmlFor="product_id"
                            className="form-input-label"
                          >
                            Prodotto
                          </label>
                          <select
                            id="product_id"
                            name="product_id"
                            className="form-input"
                            onChange={handleChange}
                          >
                            <option value="">Seleziona un prodotto</option>
                            {products().map(product => (
                              <option key={product.id} value={product.id}>
                                {product.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="formula"
                            className="form-input-label"
                          >
                            Formula
                          </label>
                          <input
                            id="formula"
                            name="formula"
                            type="text"
                            onChange={handleChange}
                            placeholder="Inserisci formula"
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="verbose"
                            className="form-input-label"
                          >
                            Formula visibile
                          </label>
                          <input
                            id="verbose"
                            name="verbose"
                            type="text"
                            onChange={handleChange}
                            placeholder="Inserisci formula visibile"
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="type"
                            className="form-input-label"
                          >
                            Tipo
                          </label>
                          <select
                            id="type"
                            name="type"
                            onChange={handleChange}
                            className="form-input"
                          >
                            <option value="">Seleziona un tipo</option>
                            <option value={0}>Calcolo</option>
                            <option value={1}>Flat</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          onClick={() => {
                            setModal(false)
                          }}
                          type="submit"
                          className="form-insert-button"
                        >
                          Aggiungi
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </InsertModale>
          </div>
          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica Categoria"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label
                          htmlFor="product_id"
                          className="form-input-label"
                        >
                          Prodotto
                        </label>
                        <select
                          id="product_id"
                          name="product_id"
                          className="form-input"
                          onChange={handleChange}
                          defaultValue={edit.product_id}
                        >
                          {products().map(product => (
                            <option key={product.id} value={product.id}>
                              {product.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="formula"
                          className="form-input-label"
                        >
                          Formula
                        </label>
                        <input
                          id="formula"
                          name="formula"
                          type="text"
                          onChange={handleChange}
                          className="form-input"
                          defaultValue={edit.formula}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="verbose"
                          className="form-input-label"
                        >
                          Formula visibile
                        </label>
                        <input
                          id="verbose"
                          name="verbose"
                          type="text"
                          onChange={handleChange}
                          className="form-input"
                          defaultValue={edit.verbose}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="type"
                          className="form-input-label"
                        >
                          Tipo
                        </label>
                        <select
                          id="type"
                          name="type"
                          onChange={handleChange}
                          defaultValue={edit.type}
                          className="form-input"
                        >
                          <option value={0}>Calcolo</option>
                          <option value={1}>Flat</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Modifica
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
          <Table columns={columns} data={data} />
        </>
      )}
    </section>
  )
}

export default Formule
