import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { getApiToken } from "../../../services/auth"
import Loading from "../../Loading"
import Seo from "../../seo"
import Table from "../Table"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"
import InsertModale from "../InsertModale"
const Piattaforme = () => {
  
  const [piattaforma, setPiattaforma] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [modalEdit, setModalEdit] = useState(false)
  const [edit, setEdit] = useState([])
  const [modal, setModal] = useState(false)
  const [values, setValues] = useState({
    name: "",
    url: "",
    param: "",
  })
  const handleSubmit = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const urls = process.env.CLICK_API_PLATFORMS
    try {
      await axios.post(urls, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setModal(false)
      toast.success("Piattaforma inserita con successo")
      setValues({
        name: "",
        url: "",
        param: "",
      })
      getPiattaforma()
    } catch (error) {
      setModal(false)
      toast.error("Errore nell'inserimento della piattaforma")
    }
  }
  useEffect(() => {
    getPiattaforma()
    // eslint-disable-next-line
  }, [])
  const getPiattaforma = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_PLATFORMS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setPiattaforma(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "URL",
        accessor: row => (
          <a
            className="text-blue-400"
            target="_blank"
            rel="noreferrer"
            href={row.url}
          >
            {row.url}
          </a>
        ),
      },
      {
        Header: "Modificare",
        accessor: "action",
        Cell: row => (
          <div className="flex justify-center">
            <button
              className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
              onClick={e => handleEdit(row.row.original)}
            >
              Modifica
            </button>
          </div>
        ),
      },
    ],
    []
  )

  const data = useMemo(() => piattaforma, [piattaforma])
  const handleEdit = row => {
    setEdit(row)
    setModalEdit(true)
  }
  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const dataToSend = {
      name: e.target.name.value,
      url: e.target.url.value,
      param: e.target.param.value,
    }
    const url = `${process.env.CLICK_API_PLATFORMS}/${edit.id}`
    try {
      const response = await axios({
        url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataToSend,
      })
      if (response.status === 200) {
        setModalEdit(false)
        getPiattaforma()
        toast.success("Piattaforma aggiornata")
      }
    } catch (error) {
      toast.error("Errore")
    }
  }
  const handleChange = e => {
    setEdit({ ...edit, [e.target.name]: e.target.value })
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Gestione piattaforme" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
            Gestione piattaforme
          </h2>
          <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          <button className="super-btn" onClick={() => setModal(true)}>
            Inserisci
          </button>
          <InsertModale
            modal={modal}
            setModal={setModal}
            title={"Inserisci Dati"}
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleSubmit}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label
                          htmlFor="name"
                          className="form-input-label"
                        >
                          Nome
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-input"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="url"
                          className="form-input-label"
                        >
                          URL
                        </label>
                        <input
                          id="url"
                          name="url"
                          type="text"
                          className="form-input"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="param"
                          className="form-input-label"
                        >
                          Parametri
                        </label>
                        <input
                          id="param"
                          name="param"
                          type="text"
                          className="form-input"
                          onChange={handleChange}
                        />
                        </div>
                    </div>
                    <div className="mt-4">
                      <button
                        onClick={() => setModal(false)}
                        type="submit"
                        className="form-insert-button"
                      >
                        Aggiungi
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </InsertModale>
          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica piattaforma"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="name"
                        >
                          Nome
                        </label>
                        <input
                          className="form-input"
                          id="name"
                          type="text"
                          name="name"
                          required
                          onChange={handleChange}
                          defaultValue={edit.name}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="url"
                        >
                          URL
                        </label>
                        <input
                          className="form-input"
                          id="url"
                          type="text"
                          name="url"
                          required
                          onChange={handleChange}
                          defaultValue={edit.url}
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="param"
                        >
                          Parametri
                        </label>
                        <input
                          className="form-input"
                          id="param"
                          type="text"
                          name="param"
                          required
                          onChange={handleChange}
                          defaultValue={edit.param}
                        />
                        </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Modifica
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
          <Table columns={columns} data={data} />
        </>
      )}
    </section>
  )
}

export default Piattaforme
