import React, { useMemo, useState, useEffect } from "react"
import axios from "axios"
import Table, { StatusPill } from "../Table"
import Seo from "../../seo"
import Loading from "../../Loading"
import { getApiToken } from "../../../services/auth"
import InsertModale from "../InsertModale"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"
const DettaglioPunti = () => {
  const PointsList = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      id: "user_id",
      Header: "User id",
      accessor: "user_id",
    },
    {
      Header: "Utente",
      accessor: "user_email",
    },
    {
      Header: "Prodotto",
      accessor: row => {
        return (
          <p>
            {row.product.display_name
              ? row.product.display_name
              : row.product.name}
          </p>
        )
      },
    },
    {
      Header: "Punti totali",
      accessor: "points_total",
    },
    {
      Header: "Punti utente",
      accessor: "points_user",
    },
    {
      Header: "Punti admin",
      accessor: "points_admin",
    },
    {
      Header: "Pubblicato",
      accessor: row => {
        switch(row.published){
          case 0:
            return "In sospeso"
          case 1:
            return "Approvato";
          case 2:
            return "In scadenza";
          case 3: 
            return "Scaduto";
          default:
            return "-";
        }
      },
      Cell: StatusPill,
    },
    {
      Header: "Riscattato",
      accessor: "claimed",
    },
    {
      Header: "Modificare",
      accessor: "action",
      Cell: row => (
        <div className="flex justify-center">
          <button
            className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
            onClick={e => handleEdit(row.row.original)}
          >
            Modifica
          </button>
        </div>
      ),
    },
  ]
  const [adminPoints, setAdminPoints] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [edit, setEdit] = useState([])

  const handleEdit = row => {
    setEdit(row)
    setModalEdit(true)
  }

  const [values, setValues] = useState({
    user_id: "",
    product_id: "",
    points_total: "",
    points_user: "",
    points_admin: "",
    published: "",
    claimed: "",
    data: "",
    note: "",
    external_id: "",
  })
  const handleSubmit = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = process.env.CLICK_API_ADMIN_POINTS
    try {
      await axios({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          user_id: values.user_id,
          product_id: parseInt(values.product_id),
          points_total: parseInt(values.points_total),
          points_user: parseInt(values.points_user),
          points_admin: parseInt(values.points_admin),
          published: parseInt(values.published),
          claimed: parseInt(values.claimed),
          data: values.data,
          note: values.note,
          external_id: values.external_id,
        },
      })
      fetchAdminPoints()
      toast.success("L'articolo è stato aggiunto con successo")
    } catch (error) {
      setError(error)
      toast.error("Errore durante l'aggiunta dell'articolo")
    }
  }
  const handleChange = e => {
    e.preventDefault()
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const fetchAdminPoints = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_ADMIN_POINTS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { type: "form" },
      })
      setAdminPoints(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }
  useEffect(() => {
    fetchAdminPoints()
    // eslint-disable-next-line
  }, [])
  const toggleModal = () => {
    setModal(!modal)
  }

  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const dataToSend = {
      user_id: e.target.elements.user_id.value,
      product_id: parseInt(e.target.elements.product_id.value),
      points_total: parseInt(e.target.elements.points_total.value),
      points_user: parseInt(e.target.elements.points_user.value),
      points_admin: parseInt(e.target.elements.points_admin.value),
      data: e.target.elements.data.value,
      claimed: parseInt(e.target.elements.claimed.value),
      published: parseInt(e.target.elements.published.value),
      note: e.target.elements.note.value,
      external_id: e.target.elements.external_id.value,
    }
    const url = `${process.env.CLICK_API_ADMIN_POINTS}/${edit.id}`
    try {
      await axios({
        url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataToSend,
      })
      setModalEdit(false)
      fetchAdminPoints()
      toast.success("Punti aggiornati con successo")
    } catch (error) {
      setError(error)
      toast.error("Errore durante l'aggiornamento")
    }
  }
  const { points, products, users } = adminPoints
  const columns = useMemo(() => PointsList, []) // eslint-disable-line
  const data = useMemo(() => points, [points])
  return (
    <section className="max-w-full mx-auto px-4 sm:px-6 my-8">
      <Seo title="Dettaglio Punti" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />

          <div className="">
            <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
              Dettaglio punti
            </h2>
            <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          </div>
          <div>
            <button className="super-btn" onClick={toggleModal}>
              Inserisci
            </button>
            <InsertModale
              modal={modal}
              setModal={setModal}
              title={"Inserisci Dati"}
            >
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="shadow-md overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3">
                          <label
                            htmlFor="user_id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Utente
                          </label>
                          <input
                            list="user_email"
                            id="user_id"
                            name="user_id"
                            className="block appearance-none w-full px-4 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-amber-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            onChange={handleChange}
                            placeholder="Seleziona utente"
                          />
                          <datalist id="user_email">
                            {users.map(user => (
                              <option key={user.id} value={user.id}>
                                {user.email}
                              </option>
                            ))}
                          </datalist>
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="product_id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prodotto
                          </label>
                          <input
                            list="product_name"
                            id="product_id"
                            name="product_id"
                            className="block appearance-none w-full px-4 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none  focus:ring-amber-400 focus:border-amber-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            onChange={handleChange}
                            placeholder="Seleziona prodotto"
                          />
                          <datalist id="product_name">
                            {products.map(product => (
                              <option key={product.id} value={product.id}>
                                {product.name}
                              </option>
                            ))}
                          </datalist>
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="points_total"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Punti totali
                          </label>
                          <input
                            id="points_total"
                            name="points_total"
                            type="text"
                            placeholder="Punti totali"
                            required
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="points_user"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Punti utente
                          </label>
                          <input
                            id="points_user"
                            name="points_user"
                            type="text"
                            placeholder="Punti utente"
                            required
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="points_admin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Punti amministratore
                          </label>
                          <input
                            id="points_admin"
                            name="points_admin"
                            type="text"
                            placeholder="Punti amministratore"
                            required
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="data"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Data
                          </label>
                          <input
                            id="data"
                            name="data"
                            type="date"
                            placeholder="Data"
                            required
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="claimed"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Totale riscattato
                          </label>
                          <input
                            id="claimed"
                            name="claimed"
                            type="text"
                            placeholder="Richiesto"
                            required
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                        <div className="col-span-3 ">
                          <label
                            htmlFor="published"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Stato
                          </label>

                          <select
                            id="published"
                            name="published"
                            onChange={handleChange}
                            className="mt-1 appearance-none focus:ring-amber-400 focus:border-amber-400 block  shadow-sm sm:text-sm border-gray-300 rounded-full"
                          >
                            <option value="">--</option>
                            <option value={0}>In sospeso</option>
                            <option value={1}>Approvato</option>
                            <option value={2}>In scadenza</option>
                            <option value={3}>Scaduto</option>
                          </select>
                        </div>

                        <div className="col-span-6 ">
                          <textarea
                            id="note"
                            name="note"
                            type="text"
                            onChange={handleChange}
                            rows={6}
                            className="shadow-sm text-gray-900 focus:ring-amber-500 focus:border-amber-500 mt-6 block w-full sm:text-sm border border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-6 ">
                          <label
                            htmlFor="external_id"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ID Esterno
                          </label>
                          <input
                            id="external_id"
                            name="external_id"
                            type="text"
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        onClick={() => setModal(false)}
                        type="submit"
                        className="form-insert-button"
                      >
                        Aggiungi
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </InsertModale>
          </div>

          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica Dati"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 ">
                        <label
                          htmlFor="user_id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Utente
                        </label>
                        <select
                          id="user_id"
                          name="user_id"
                          required
                          onChange={handleChange}
                          defaultValue={edit.user_id}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-400 focus:border-amber-400 sm:text-sm"
                        >
                          <option value="">Seleziona</option>
                          {users.map(user => (
                            <option key={user.id} value={user.id}>
                              {user.email}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-3 ">
                        <label
                          htmlFor="product_id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prodotto
                        </label>
                        <select
                          id="product_id"
                          name="product_id"
                          required
                          onChange={handleChange}
                          defaultValue={edit.product_id}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-amber-400 focus:border-amber-400 sm:text-sm"
                        >
                          <option value="">Seleziona</option>
                          {products.map(product => (
                            <option key={product.id} value={product.id}>
                              {product.name} ({product.id})
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-3 ">
                        <label
                          htmlFor="points_total"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Punti totali
                        </label>
                        <input
                          d="points_total"
                          name="points_total"
                          type="text"
                          placeholder="Punti totali"
                          required
                          defaultValue={edit.points_total}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3 ">
                        <label
                          htmlFor="points_user"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Punti utente
                        </label>
                        <input
                          id="points_user"
                          name="points_user"
                          type="text"
                          placeholder="Punti utente"
                          required
                          defaultValue={edit.points_user}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3 ">
                        <label
                          htmlFor="points_admin"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Punti amministratore
                        </label>
                        <input
                          id="points_admin"
                          name="points_admin"
                          type="text"
                          placeholder="Punti amministratore"
                          required
                          defaultValue={edit.points_admin}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          htmlFor="data"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Data
                        </label>
                        <input
                          id="data"
                          name="data"
                          type="date"
                          placeholder="Data"
                          required
                          defaultValue={edit.data}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3 ">
                        <label
                          htmlFor="claimed"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Totale riscattato
                        </label>
                        <input
                          id="claimed"
                          name="claimed"
                          type="text"
                          placeholder="Richiesto"
                          required
                          defaultValue={edit.claimed}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3 ">
                        <label
                          htmlFor="published"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Stato
                        </label>
                        <select
                          id="published"
                          name="published"
                          required
                          defaultValue={edit.published}
                          className="mt-1 appearance-none focus:ring-amber-400 focus:border-amber-400 block  shadow-sm sm:text-sm border-gray-300 rounded-full"
                        >
                            <option value={0}>In sospeso</option>
                            <option value={1}>Approvato</option>
                            <option value={2}>In scadenza</option>
                            <option value={3}>Scaduto</option>
                        </select>
                      </div>

                      <div className="col-span-6 ">
                        <textarea
                          id="note"
                          name="note"
                          type="text"
                          rows={6}
                          className="shadow-sm text-gray-900 focus:ring-amber-500 focus:border-amber-500 mt-6 block w-full sm:text-sm border border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 ">
                        <label
                          htmlFor="external_id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ID Esterno
                        </label>
                        <input
                          id="external_id"
                          name="external_id"
                          type="text"
                          className="form-input"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Salva
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>

          <Table
            columns={columns}
            data={data}
            initialState={{ hiddenColumns: "user_id" }}
          />
        </>
      )}
    </section>
  )
}

export default DettaglioPunti
