import React, { useState, useEffect } from "react"
import axios from "axios"
import Seo from "../seo"
import { Link } from "gatsby"
import { getApiToken } from "../../services/auth"
import Loading from "../Loading"
import defaultImage from "../../images/default.png"
const DettaglioPremi = () => {
  const [cards, setCards] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()

  const getCards = async () => {
    const url = process.env.CLICK_API_GIFTCARDS
    const token = await getApiToken()
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setCards(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    getCards()
  }, [])

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Dettaglio Premi" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
            Dettaglio Premi
          </h2>
          <div className="my-8 h-2 bg-slate-400 border rounded-full" />
          <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8">
            {cards.map(card => {
              const { id, img, name } = card
              return (
                <div
                  key={id}
                  className="pb-8 pt-1 px-2 max-w-sm mx-auto bg-white rounded-xl shadow sm:flex flex-col hover:bg-amber-50  hover:shadow-lg transition hover:border hover:border-amber-100"
                >
                  <Link to={`/account/profile/premi/${id}`}>
                    <div className=" py-1 flex justify-center">
                      <img
                        className="mx-auto w-full h-auto sm:mx-0 sm:shrink-0 mb-4"
                        src={
                          img
                            ? `https://clicknpay.it/static-images/prizes/${img}`
                            : defaultImage
                        }
                        alt={name}
                      />
                    </div>
                  </Link>
                  <hr className="mb-4" />
                  <div className="text-center space-y-10">
                    <div className="space-y-0.5">
                      <p className="text-sm font-medium text-black">{name}</p>
                    </div>

                    <Link
                      to={`/account/profile/premi/${id}`}
                      className="px-2 py-1 mt-2 text-sm text-sky-900 font-medium inline-flex items-center justify-between rounded-md border border-sky-900  hover:bg-super hover:border-transparent hover:transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-super focus:ring-offset-2"
                    >
                      scopri di più
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </section>
  )
}

export default DettaglioPremi
