import React, { useMemo, useState, useEffect } from "react"
import Seo from "../seo"
import { getApiToken, useAuth0 } from "../../services/auth"
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table"
import { COL } from "./Col"
import DataSearch from "./DataSearch"
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaCheckCircle,
  FaTimesCircle,
  FaChevronLeft, FaChevronRight
} from "react-icons/fa"
import Loading from "../Loading"
import { Link } from "gatsby"
import axios from "axios"

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <>
      <select
        className="focus:ring-amber-500 focus:border-amber-50 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-amber-100"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">-- TUTTI --</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  )
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown"

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold rounded-full shadow-sm",
        status.startsWith("√") ? "bg-green-100 text-green-700" : null,
        status.startsWith("☓") ? "bg-red-100 text-red-500" : null
      )}
    >
      {status}
    </span>
  )
}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0)
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0)

    preFilteredRows.forEach(row => {
      const rowDate = new Date(row.values[id])

      min = rowDate <= min ? rowDate : min
      max = rowDate >= max ? rowDate : max
    })

    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div className="">
      <input
        className="focus:ring-amber-500 focus:border-amber-50 rounded-none rounded-r-md sm:text-sm border-amber-100"
        min={
          min.getFullYear() + "-" + (min.getMonth() + 1) + "-" + min.getDate()
        }
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? val : undefined, old[1]])
        }}
        type="date"
        lang="it-IT"
        value={filterValue[0] || ""}
      />
      {" A "}
      <input
        className="focus:ring-amber-500 focus:border-amber-50 rounded-none rounded-r-md sm:text-sm border-amber-100"
        max={
          max.getFullYear() + "-" + (max.getMonth() + 1) + "-" + max.getDate()
        }
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [
            old[0],
            val ? val.concat("T23:59:59.999Z") : undefined,
          ])
        }}
        type="date"
        lang="it-IT"
        value={filterValue[1]?.slice(0, 10) || ""}
      />
    </div>
  )
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const startDate = filterValues[0] ? new Date(filterValues[0]) : undefined
  const endDate = filterValues[1] ? new Date(filterValues[1]) : undefined
  return rows.filter(row => {
    const date = new Date(row.values[id])
    if (startDate && endDate) {
      return date >= startDate && date <= endDate
    } else if (startDate) {
      return date >= startDate
    } else if (endDate) {
      return date <= endDate
    }
    return true
  })
}

dateBetweenFilterFn.autoRemove = value => {
  if (!value[0] && !value[1]) {
    return true
  }
  return false
}

const Punti = () => {
  const { user } = useAuth0()
  const [userPoints, setUserPoints] = useState([])
  const [pointsNotRedeemed, setPointsNotRedeemed] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [refreshData, setRefreshData] = useState(false)
  const fetchUserPoints = async () => {
    const token = await getApiToken()
    try {
      const response = await axios.get(`${process.env.CLICK_API_POINTS}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setUserPoints(response.data.points)
      setPointsNotRedeemed(response.data.countExp >= riscattaPonti ? response.data.countExp : response.data.countApproved)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }
  useEffect(() => {
    fetchUserPoints()
    // eslint-disable-next-line
  }, [refreshData])
  const columns = useMemo(() => COL, [])
  const data = useMemo(() => userPoints, [userPoints])

  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state
  let riscattaPonti = process.env.RISCATTARE_POINTS

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Dettaglio punti" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <div className="">
            <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
              Dettaglio punti di {user?.given_name}
            </h2>
            <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          </div>

          <DataSearch
            filter={globalFilter}
            setFilter={setGlobalFilter}
            headerGroups={headerGroups}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />

          <div className="flex flex-col">
            <div className="">
              <div
                className={`${
                  pointsNotRedeemed >= riscattaPonti
                    ? "px-4 py-5 sm:px-6 bg-green-100  my-3 border border-green-200 shadow-md rounded-md "
                    : "px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md"
                }`}
              >
                <div className="">
                  {pointsNotRedeemed >= riscattaPonti ? (
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <p className="text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider ">
                        Totale punti non riscattati:{" "}
                        <span className="font-bold">{pointsNotRedeemed}</span>,
                        puoi ora riscattare il tuo benefit{" "}
                        <Link
                          to="/account/profile/riscatta"
                          className="text-green-700 font-bold hover:underline cursor-pointer"
                        >
                          QUI
                        </Link>
                      </p>
                      <FaCheckCircle className="w-6 h-6 text-green-500 mt-4 md:mt-0" />
                    </div>
                  ) : (
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <p className="text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider ">
                        Totale punti non riscattati:{" "}
                        <span className="font-bold">{pointsNotRedeemed}</span>,
                        ottieni altri {riscattaPonti - pointsNotRedeemed} per
                        poter riscattare il tuo benefit
                      </p>
                      <FaTimesCircle className="w-6 h-6 text-red-500 mt-4 md:mt-0" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow lg:shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table
                    className="min-w-full divide-y divide-gray-200"
                    {...getTableProps}
                  >
                    {/* Table header */}
                    <thead className="bg-gray-100">
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th
                              scope="col"
                              className="group border-x px-6 py-2 md:py-4 text-center text-xs md:text-sm font-normal md:font-medium text-gray-500 uppercase tracking-wider"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FaSortDown className="w-4 h-4 text-gray-400 inline-flex" />
                                  ) : (
                                    <FaSortUp className="w-4 h-4 text-gray-400 inline-flex" />
                                  )
                                ) : (
                                  <FaSort className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 inline-flex" />
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {/* Table body */}
                    <tbody
                      className="bg-white divide-y divide-gray-200 text-center"
                      {...getTableBodyProps}
                    >
                      {(page.length > 0 &&
                        page.map(row => {
                          prepareRow(row)
                          return (
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50 hover:bg-slate-200"
                              {...row.getRowProps()}
                            >
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    className="px-6 border-x py-4 whitespace-nowrap text-xs md:text-base font-medium text-gray-900 leading-5 capitalize"
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })) || (
                        <tr className="border-b odd:bg-white even:bg-gray-50 hover:bg-slate-200">
                          <td
                            className="px-6 py-4 whitespace-nowrap text-red-500 leading-5  uppercase text-center"
                            colSpan="8"
                          >
                            <span>nessun risultato trovato</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* Pagination */}
                <div className="flex justify-between items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  <button
                    className={`${canPreviousPage ? "" : "opacity-50"}`}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <li className="list-none">
                      <span className="flex items-center justify-center w-10 h-10 text-slate-600 transition-colors duration-150 rrounded-l-lg focus:shadow-outline hover:bg-slate-100">
                        <FaChevronLeft className="h-7" />
                      </span>
                    </li>
                  </button>
                  <span>
                    Pagina{" "}
                    <strong>
                      {pageIndex + 1} di {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <select
                    className="  pl-3 pr-7 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm sm:leading-5"
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostra {pageSize}
                      </option>
                    ))}
                  </select>
                  <button
                    className={`${canNextPage ? "" : "opacity-50"}`}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <li className="list-none">
                      <span className="flex items-center justify-center w-10 h-10 text-slate-600 transition-colors duration-150 bg-white rounded-r-lg focus:shadow-outline hover:bg-slate-100">
                        <FaChevronRight className="h-7" />
                      </span>
                    </li>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default Punti
