import React, { useEffect, useState, useRef } from "react"
import Seo from "../seo"
import { getApiToken, useAuth0 } from "../../services/auth"
import ModalContact from "../Modal"
import EditModal from "../admin/EditModal"
const Modifica = () => {
  const { user, logout } = useAuth0();
  const url = process.env.CLICK_API_MODIFICA
  const handleUserInfo = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const details = {
      given_name: e.target.elements.given_name.value,
      family_name: e.target.elements.family_name.value,
      user_metadata: {
        birth_date: e.target.elements.birth_date.value,
        phone_number: e.target.elements.phone_number.value,
        full_address: {
          address: e.target.elements.address.value,
          city: e.target.elements.city.value,
          province: e.target.elements.province.value,
          postal_code: e.target.elements.postal_code.value,
        },
      },
    }
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
    await response.text()
  }
  const form = useRef(null);
  
    const submit = async e => {
      e.preventDefault()
      const token = await getApiToken()
      const data = new FormData(form.current)
      try{
      const response = await fetch(process.env.CLICK_API_DOCUMENTS, { method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
        },
      body: data })
      if(response.ok){
        toggleModalDoc()
        getUserInfo()
      }
    }catch(err){
      console.log(err, 'TODO');
    }
    }  
  
  const [userInfo, setUserInfo] = useState()
  const [emailDel, setEmailDel] = useState()

  const getUserInfo = async () => {
    const token = await getApiToken()
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()
    setUserInfo(data)
  }
  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line
  }, [])

  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const toggleModal = () => {
    setOpen(!open)
  }
  const toggleModalDoc = () => {
    setOpenDoc(!openDoc)
  }

  const handleChange = event => {
    setUserInfo({
      ...userInfo,
      user_metadata: {
        ...userInfo.user_metadata,
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleChangeEmail = event => {
    setEmailDel({
        [event.target.name]: event.target.value
    })
  }

  const getUserDocs = async type => {
    const token = await getApiToken()
    const res = await fetch(process.env.CLICK_API_DOCUMENTS + '?' + new URLSearchParams({
      type: type}), { method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
      }
    });
    const contentDisposition = res.headers.get('content-disposition');
    console.log(contentDisposition);
    const filename = contentDisposition.match(/filename="(.+)"/)[1]
    const blob = await res.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  }

  const handleDelete = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const dataToSend = emailDel
    const url = process.env.CLICK_API_MODIFICA
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
      if(response.ok){
        logout();
      }
      setModalEdit(false)
    } catch (error) {
      console.log(error) //TODO
    }
  }

  const handlePwReset = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = process.env.CLICK_API_RESET_PW
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      if(response.ok){
        const { ticket } = await response.json();
        window.location.assign(ticket);
      }
      setModalEdit(false)
    } catch (error) {
      console.log(error) //TODO
    }
  }
  
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Modifica" />
      <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
        Modifica Profilo
      </h2>
      <div className="my-8 h-2 bg-slate-400 border rounded-full" />

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Modifica le informazioni personali.
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Usa questo form per modificare le informazioni personali, come
                nome, cognome, email, telefono, etc...
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleUserInfo}>
              <div className="shadow-md overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="given_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nome
                      </label>
                      <input
                        type="text"
                        name="given_name"
                        id="given_name"
                        required
                        onChange={handleChange}
                        defaultValue={userInfo && userInfo?.given_name}
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white "
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="family_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cognome
                      </label>
                      <input
                        type="text"
                        name="family_name"
                        id="family_name"
                        required
                        onChange={handleChange}
                        defaultValue={userInfo && userInfo?.family_name}
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="phone_number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numero di telefono
                      </label>
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo && userInfo.user_metadata?.phone_number
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="birth_date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data di nascita
                      </label>
                      <input
                        type="date"
                        name="birth_date"
                        id="birth_date"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo && userInfo.user_metadata?.birth_date
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-400 focus:border-amber-400 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Indirizzo di residenza
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo &&
                          userInfo.user_metadata?.full_address?.address
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Città
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo && userInfo.user_metadata?.full_address?.city
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="province"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Provincia
                      </label>
                      <input
                        type="text"
                        name="province"
                        id="province"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo &&
                          userInfo.user_metadata?.full_address?.province
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal_code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        CAP
                      </label>
                      <input
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        required
                        onChange={handleChange}
                        defaultValue={
                          userInfo &&
                          userInfo.user_metadata?.full_address?.postal_code
                        }
                        className="mt-1 text-blue-700 focus:text-black focus:ring-amber-500 focus:border-amber-500 block w-full shadow-sm sm:text-sm border-amber-200 rounded-md bg-amber-100 focus:bg-white"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <div className="flex justify-between px-4 py-3 bg-gray-50 text-right flex-col sm:px sm:flex-row">
                      <button 
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-50 bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 my-2 mx-4 order-last sm:order-1 sm:m-0"
                      type="button"
                      onClick={() => setModalEdit(true)}>Cancella account</button>
                      {
                user && user?.sub.startsWith('auth0') ? (
                      <button type="button" className="inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-md bg-transparent border border-sky-700 text-sky-700 hover:bg-sky-700 hover:text-white hover:shadow-md hover:shadow-sky-200 my-2 mx-4 order-2 sm:m-0" onClick={handlePwReset}>Cambia password</button>
                      )
                      :
                      ''
                      }
                      <button
                    type="submit"
                    id="save"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-sky-900 bg-super hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-400 my-2 mx-4 order-1 sm:order-last sm:m-0"
                    onClick={toggleModal}
                  >
                    Salva
                  </button>
                  </div>
                  <ModalContact
                    open={open}
                    setOpen={setOpen}
                    title="Modifica informazioni personali"
                    message="Le informazioni personali sono state modificate con successo!"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <div className="mt-10 sm:mt-0" id="verify">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Dati di verifica
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Per poter riscattare i premi è necessario verificare la tua
                identità, allega due selfie con in mano il tuo documento ben
                visibile del fronte e del retro
              </p>
              {
                userInfo && userInfo?.has_doc ? 
                (
                  <p className="mt-1 text-green-600">
                    <strong>Allegati caricati con successo</strong>
                  </p>
                )
                :
                ''
              }
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
          <form ref={form} onSubmit={submit}>
               <div className="shadow-md overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="document_front"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Selfie con fronte
                      </label>
                      <input
                        type="file"
                        name="document_front"
                        id="document_front"
                        className="mt-1 file:borde file:border-solid file:border-amber-500 block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-amber-50 file:text-amber-700 hover:file:bg-amber-100 "
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="document_back"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Selfie con retro
                      </label>
                      <input
                        type="file"
                        name="document_back"
                        id="document_back"
                        className="mt-1 file:borde file:border-solid file:border-amber-500 block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-amber-50 file:text-amber-700 hover:file:bg-amber-100 "
                      />
                    </div>
                  </div>
                </div>
                {userInfo && userInfo?.has_doc ? 
                (
                <div className="flex justify-between px-4 py-3 bg-gray-50 text-right flex-col sm:px sm:flex-row">
                      <button type="button" className="inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-md bg-transparent border border-sky-700 text-sky-700 hover:bg-sky-700 hover:text-white hover:shadow-md hover:shadow-sky-200 my-2 mx-4 sm:m-0" onClick={() => getUserDocs('front')}>Scarica fronte</button>
                      <button type="button" className="inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-md bg-transparent border border-sky-700 text-sky-700 hover:bg-sky-700 hover:text-white hover:shadow-md hover:shadow-sky-200 my-2 mx-4 sm:m-0" onClick={() => getUserDocs('back')}>Scarica retro</button>
                      <button
                    type="submit"
                    id="save"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-sky-900 bg-super hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-400 my-2 mx-4 sm:m-0"
                  >
                    Salva
                  </button>
                  </div>
                    )
                    : 
                    (
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        id="save"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-sky-900 bg-super hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-400"
                      >
                        Salva
                      </button>    
                    </div>
                    ) 
                    }
                <ModalContact
                    open={openDoc}
                    setOpen={setOpenDoc}
                    title="Modifica informazioni personali"
                    message="Allegati inviati con successo!"
                  />
              </div>
            </form>
          </div>
        </div>
      </div>
      <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Sei sicuro?"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleDelete}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-6">
                        <label
                          className="form-input-label"
                          htmlFor="email"
                        >
                          Inserisci l'email
                        </label>
                        <input
                          id="email"
                          type="text"
                          name="email"
                          onChange={handleChangeEmail}
                          className="form-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button bg-red-400 hover:bg-red-500"
                    >
                      Cancella account
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
    </section>
  )
}

export default Modifica
