import moment from "moment"
import "moment/locale/it"
import { DateRangeColumnFilter, SelectColumnFilter, StatusPill } from "./Punti"
export const COL = [
  /* {
    Header: "Id",
    accessor: "id",
  }, */
  {
    Header: "Servizio",
    accessor: "product.name",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Punti",
    accessor: "points_user",
  },
  {
    Header: "Stato Riscattato",
    accessor: hrr => {
      if (hrr.claimed) {
        return "√"
      }
      if (hrr.points_user - hrr.claimed > 0) {
        return "☓"
      }
      return "Riscattato"
    },

    Filter: SelectColumnFilter,
    Cell: StatusPill,
  },
  {
    Header: "Data",
    accessor: "data",
    Cell: row => (row.value ? moment(row.value).format("DD/MM/yyyy") : "-"),
    Filter: DateRangeColumnFilter,
    filter: "dateBetween",
  },
  {
    Header: "Stato",
    accessor: row => {
      switch(row.published){
        case 1:
          return "Approvato";
        case 2:
          return "In scadenza";
        case 3: 
          return "Scaduto";
        default:
          return "-";
      }
    }
  }
  /* {
    Header: "Data inizio",
    accessor: "created",
    Cell: row => (row.value ? moment(row.value).format("DD/MM/YYYY") : "-"),
  },
  {
    Header: "Data fine",
    accessor: "modified",
    Cell: row => (row.value ? moment(row.value).format("DD/MM/YYYY") : "-"),
  }, */
]
