import React from "react"
import { Router } from "@reach/router"
import Profile from "../components/authentication/Profile"
import PrivateRoute from "../components/authentication/PrivateRoute"
import Riepilogo from "../components/profilo/Riepilogo"
import Punti from "../components/profilo/Punti"
import Riscatta from "../components/profilo/Riscatta"
import Modifica from "../components/profilo/Modifica"
import ListaUtenti from "../components/admin/Utenti/ListaUtenti"
import DettaglioPunti from "../components/admin/Utenti/DettaglioPunti"
import DettaglioRichieste from "../components/admin/Utenti/DettaglioRichieste"
import Piattaforme from "../components/admin/Affiliate/Piattaforme"
import Categorie from "../components/admin/Affiliate/Categorie"
import Programmi from "../components/admin/Affiliate/Programmi"
import Prodotti from "../components/admin/Affiliate/Prodotti"
import Formule from "../components/admin/Affiliate/Formule"
import DettaglioPremi from "../components/profilo/DettaglioPremi"
import SingolaCarta from "../components/profilo/SingolaCarta"


const Account = () => { 
  return (
    <>
      <>
        {/* User Router */}
        <Profile />
        <Router>
          <PrivateRoute path="/account/profile" component={Riepilogo} />
          <PrivateRoute path="/account/profile/punti" component={Punti} />
          <PrivateRoute path="/account/profile/riscatta" component={Riscatta} />
          <PrivateRoute path="/account/profile/modifica" component={Modifica} />
          <PrivateRoute path="/account/profile/premi" component={DettaglioPremi} />
          <PrivateRoute path="/account/profile/premi/:id" component={SingolaCarta} />

          {/* Admin Router */}
          <PrivateRoute path="/account/admin/utenti" component={ListaUtenti} />
          <PrivateRoute
            path="/account/admin/punti"
            component={DettaglioPunti}
          />
          <PrivateRoute
            path="/account/admin/richieste"
            component={DettaglioRichieste}
          />

          <PrivateRoute
            path="/account/admin/piattaforme"
            component={Piattaforme}
          />
          <PrivateRoute path="/account/admin/categorie" component={Categorie} />
          <PrivateRoute path="/account/admin/programmi" component={Programmi} />
          <PrivateRoute path="/account/admin/prodotti" component={Prodotti} />
          <PrivateRoute path="/account/admin/formule" component={Formule} />
        </Router>
      </>
    </>
  )
}

export default Account
