import React, { useState, useEffect } from "react"
import axios from "axios"
import Seo from "../seo"
import { getApiToken } from "../../services/auth"
import Loading from "../Loading"
import defaultImage from "../../images/default.png"
const SingolaCarta = () => {
  const [card, setCard] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()

  const id = window.location.pathname.split("/")[4]
  const getCardById = async () => {
    const url = process.env.CLICK_API_GIFTCARDS + "/" + id
    const token = await getApiToken()
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setCard(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    getCardById()

    // eslint-disable-next-line
  }, [])

  const { name, img, how_to_use, points_cut, info } = card
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title={name || ""} />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:grid md:grid-flow-col md:gap-4 pt-8">
          <div className="col-span-2 order-2 md:order-1">
            <p className="text-gray-700 text-xl md:text-4xl font-semibold ">
              {name}
            </p>
            <p className="text-gray-700 md:text-xl font-medium py-10 ">
              {points_cut.map((point, index) => {
                return (
                  <span
                    key={index}
                    className="inline-block bg-sky-100 rounded-full px-3 py-1 text-sm font-semibold text-sky-800 mr-2"
                  >
                    {point}
                  </span>
                )
              })}
            </p>
          </div>
          <div className="row-span-2 col-span-2 order-3 md:order-2">
            <p className="text-xl text-sky-900 font-medium uppercase pb-2">
              Come usare
            </p>
            <div
              className="prose prose-slate lg:prose-lg prose-sky"
              dangerouslySetInnerHTML={{
                __html: how_to_use,
              }}
            />
            <p className="text-xl text-sky-900 font-medium uppercase py-6">
              Info
            </p>
            <div className="bg-white shadow-md border overflow-hidden sm:rounded-lg mb-4">
              {info?.map((some, i) => (
                <div
                  key={i}
                  className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b odd:bg-white even:bg-gray-50 hover:bg-slate-200 transition duration-150 ease-in-out"
                >
                  <dt className="text-xs md:text-base font-medium text-gray-500 uppercase">
                    {some.label}
                  </dt>
                  <dd
                    dangerouslySetInnerHTML={{ __html: some.text }}
                    className="mt-1 text-xs md:text-base text-gray-900 sm:mt-0 sm:col-span-2 prose prose-slate prose-sky"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="row-span-3 order-1 md:order-3">
            <img
              className=" mb-4 w-96 h-auto object-contain"
              src={
                img
                  ? `https://clicknpay.it/static-images/prizes/${img}`
                  : defaultImage
              }
              alt={name}
            />
            <span className="hidden md:block text-center">{name}</span>
          </div>
        </div>
      )}
    </section>
  )
}

export default SingolaCarta
