import React, { useState } from "react"
import { useAsyncDebounce } from "react-table"
const GlobalSearch = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 1000)
  return (
    <div>
      <div className="sm:overflow-hidden">
        <div className="px-4 py-5 sm:px-6  bg-amber-50 my-3 border border-amber-100 shadow-md rounded-md">
          <label
            htmlFor="filtri-ricerca"
            className="text-base md:text-lg leading-6 font-medium text-gray-900 uppercase flex justify-between flex-col lg:flex-row"
          >
            Filtri ricerca
          </label>
          <div className="grid grid-cols-4 gap-6 items-center">
            <div className="col-span-3 sm:col-span-2">
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-amber-500 bg-super text-sky-900 text-sm">
                  Ricerca {""}
                </span>
                <input
                  type="text"
                  name="filtri-ricerca"
                  id="filtri-ricerca"
                  className="focus:ring-amber-500 focus:border-amber-50 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-amber-100"
                  placeholder=""
                  value={value || ""}
                  onChange={e => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GlobalSearch
