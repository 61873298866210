import React, { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/outline"

const ModaleReicatta = ({ title, message, open, setOpen }) => {
  const cancelButtonRef = useRef(null)
  return (
    <>
      <Transition.Root show={open} as={Fragment} appear>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all bottom-52 md:bottom-0 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CheckIcon
                        className="h-6 w-6 text-green-700"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      {JSON.parse(title) ? (
                        JSON.parse(title)?.map((item, i) => (
                          <div key={i} className="">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              {item && item.link ? (
                                <>
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-500"
                                  >
                                    Link : {item.link}
                                  </a>
                                </>
                              ) : (
                                <span>Code: {item.code}</span>
                              )}
                            </h3>
                            <h2>
                              {item ? (
                                <span className="text-lg leading-6 font-medium text-gray-900">
                                  {item && item.password ? (
                                    <>Password : {item.password}</>
                                  ) : (
                                    <>{item.pin ? <>PIN : {item.pin}</> : ""}</>
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </h2>
                          </div>
                        ))
                      ) : (
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {title}
                        </h3>
                      )}

                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-super text-base font-medium text-sky-900 hover:bg-super/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-super sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default ModaleReicatta
