import React, { Fragment, useEffect, useState } from "react"
import { getApiToken, useAuth0 } from "../../services/auth"
import { Disclosure, Menu, Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { IoMdLogOut } from "react-icons/io"
import logo from "../../images/home-click.png"
import { Link } from "gatsby"
import Loading from "../Loading"
import { FaListUl, FaUsers } from "react-icons/fa"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { SiAuth0, SiContentful } from "react-icons/si"
import { IoBarChart, IoList, IoBagCheck, IoMedal, IoPerson  } from "react-icons/io5"
const navigation = [
  { name: "Riepilogo", href: "/account/profile", icon: IoBarChart },
  { name: "Dettaglio punti", href: "/account/profile/punti", icon: IoList },
  { name: "Riscatta", href: "/account/profile/riscatta", icon: IoBagCheck },
  { name: "Dettaglio premi", href: "/account/profile/premi", icon: IoMedal },
  { name: "Modifica profilo", href: "/account/profile/modifica", icon: IoPerson },
]
const servizi = {
  sections: [
    {
      id: "utenti",
      name: "Utenti",
      icon: FaUsers,
      items: [
        {
          name: "Lista Utenti",
          href: "/account/admin/utenti",
        },
        {
          name: "Dettaglio punti",
          href: "/account/admin/punti",
        },
        {
          name: "Dettaglio richieste",
          href: "/account/admin/richieste",
        },
      ],
    },
    {
      id: "affiliate",
      name: "Affiliate",
      icon: FaListUl,
      items: [
        {
          name: "Lista piattaforme",
          href: "/account/admin/piattaforme",
        },
        {
          name: "Lista categorie",
          href: "/account/admin/categorie",
        },
        {
          name: "Lista programmi",
          href: "/account/admin/programmi",
        },
        {
          name: "Lista prodotti",
          href: "/account/admin/prodotti",
        },
        {
          name: "Lista formule",
          href: "/account/admin/formule",
        },
      ],
    },
  ],
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
const activeStyles = {
  color: "#ffffff",
  fontWeight: 600,
  backgroundColor: "#111827",
}
const Profile = () => {
  const { isAuthenticated, logout, user, loading, getTokenSilently, error } = useAuth0()
  const [admin, setAdmin] = useState()
  useEffect(() => {
    const getAdmin = async () => {
      const token = await getApiToken()
      const response = await fetch(process.env.CLICK_API_IS_ADMIN_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      setAdmin(data)
    }
    getAdmin()
  }, [getTokenSilently])
  if ((loading || !user) && !error) {
    return <Loading />
  }
  if(error){
    setTimeout(logout, 2000);
    return (
      <div className="px-4 py-5 sm:px-6 bg-red-100 mx-auto w-4/5 my-3 border border-red-200 shadow-md rounded-md ">
      <p className="text-center text-gray-900 uppercase tracking-wider">
        Token non corrispondente, rieffettuare l'accesso
      </p>
    </div>
    )
  }

  return (
    <>
      <div className="">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <Link to="/" className="flex-shrink-0 flex flex-row items-center gap-4 text-gray-100 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md">
                      <img
                        className="h-8 w-auto inline"
                        src={logo}
                        alt="click pay logo"
                        style={ { "filter" : "brightness(0) invert(96%) sepia(9%) saturate(6598%) hue-rotate(318deg) brightness(106%) contrast(101%)" }}
                      />
                      Torna alla home
                    </Link>
                    <div className="hidden lg:block">
                      <div className="ml-10 flex items-center space-x-1">
                        {navigation.map(item => (
                          <Link
                            key={item.name}
                            to={item.href}
                            activeStyle={activeStyles}
                            className="text-gray-100 flex flex-row gap-2 items-center hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm"
                          > 
                            <item.icon
                                  className="flex-shrink-0 h-6 w-6 text-super"
                                />
                            {item.name}
                          </Link>
                        ))}
                        {admin && (
                          <Popover.Group
                            as="nav"
                            className="hidden lg:flex items-center space-x-1"
                          >
                            <Popover className="relative">
                              {({ open }) => (
                                <>
                                  <Popover.Button
                                    className={classNames(
                                      open
                                        ? "text-gray-100 font-semibold"
                                        : "text-gray-100",
                                      "flex flex-row items-center hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm"
                                    )}
                                  >
                                    <span>Admin</span>
                                    <ChevronDownIcon
                                      className={classNames(
                                        open
                                          ? "text-gray-100 font-medium"
                                          : "text-gray-400",
                                        "ml-2 h-5 w-5 group-hover:text-gray-100 font-medium"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                      <div className="rounded-b-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-gray-800 text-white px-5 py-6 sm:gap-8 sm:p-8">
                                          {servizi.sections.map(section => (
                                            <div key={section.id}>
                                              <div className="flex">
                                                <section.icon
                                                  className="flex-shrink-0 h-6 w-6 text-super mr-2"
                                                  aria-hidden="true"
                                                />
                                                {section.name}
                                              </div>

                                              <ul className="mt-2">
                                                {section.items.map(item => (
                                                  <li key={item.name}>
                                                    <Link
                                                      to={item.href}
                                                      className="block px-4 py-2 text-sm leading-5 text-gray-300 hover:bg-gray-700 hover:text-white"
                                                      activeStyle={activeStyles}
                                                    >
                                                      {item.name}
                                                    </Link>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          ))}
                                          <hr />
                                          <div className="flex items-center justify-between">
                                            <div className="">
                                              <a
                                                href="https://manage.auth0.com/dashboard/eu/clicknpay/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center py-2 px-5 border border-gray-500 rounded hover:bg-gray-700 transition-colors duration-200"
                                              >
                                                <SiAuth0 className="h-6 w-6 text-super mr-2" />
                                                Gestione utenti
                                              </a>
                                            </div>
                                            <div className="">
                                              <a
                                                href="https://be.contentful.com/login"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center py-2 px-5 border border-gray-500 rounded hover:bg-gray-700 transition-colors duration-200"
                                              >
                                                <SiContentful className="h-6 w-6 text-super mr-2" />
                                                Blog
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          </Popover.Group>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          {!admin &&
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.picture}
                            alt={user.given_name}
                          />}
                        </div>
                      </Menu>
                      {isAuthenticated && (
                        <button
                          onClick={() => logout()}
                          type="button"
                          className="bg-gray-800 p-1 ml-3 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                          <span className="sr-only">Log Out</span>
                          <IoMdLogOut className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="-mr-2 flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <div className="pt-4 pb-3 border-b border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.picture}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base leading-none text-white">
                        {user.given_name}
                      </div>
                    </div>
                  </div>
                </div>
                  {admin && (
                    <div className="mt-6">
                      <nav className="grid gap-y-2">
                        {servizi.sections.map(acc => (
                          <div key={acc.name}>
                            <div className="-m-3 p-3 flex items-center">
                              <acc.icon className="flex-shrink-0 h-6 w-6 text-super mr-2" />
                              <span className="ml-3 text-base font-medium text-gray-100">
                                {acc.name}
                              </span>
                            </div>

                            <ul className="mt-2">
                              {acc.items.map(item => (
                                <Disclosure.Button
                                  key={item.name}
                                  as={Link}
                                  to={item.href}
                                  className="block px-3 py-2 rounded-md text-base text-gray-200"
                                  activeStyle={activeStyles}
                                >
                                  {item.name}
                                </Disclosure.Button>
                              ))}
                            </ul>
                          </div>
                        ))}
                        <hr />
                        <div className="flex flex-col">
                          <div className="my-2">
                            <a
                              href="https://manage.auth0.com/dashboard/eu/clicknpay/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center py-2 px-5 border border-gray-500 rounded hover:bg-gray-700 transition-colors duration-200"
                            >
                              <SiAuth0 className="h-6 w-6 text-super mr-2" />
                              <span className="text-gray-200">
                                Gestione utenti
                              </span>
                            </a>
                          </div>
                          <div className="">
                            <a
                              href="https://be.contentful.com/login"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center py-2 px-5 border border-gray-500 rounded hover:bg-gray-700 transition-colors duration-200"
                            >
                              <SiContentful className="h-6 w-6 text-super mr-2" />
                              <span className="text-gray-200">Blog</span>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                  )}
                  <div className="grid grid-cols-2 grid-flow-row gap-2">
                  {navigation.map(item => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className="flex flex-col items-center justify-around h-28 p-2 pt-4 border-2 border-gray-500 rounded-lg text-base text-gray-200"
                      activeStyle={{...activeStyles, "border-color": '#4B5563'}}
                    >
                      <item.icon
                                  className="flex-shrink-0 h-8 w-8 text-super"
                                />
                        <div className="">
                          {item.name}
                        </div>
                    </Disclosure.Button>
                  ))}
                    {isAuthenticated && (
                      <button
                        onClick={() => logout()}
                        type="button"
                        className="flex flex-col items-center justify-around h-28 p-2 pt-4 border-2 border-gray-500 rounded-lg text-base text-gray-200"
                      >
                        <span className="sr-only">Log Out</span>
                        <IoMdLogOut
                          className="flex-shrink-0 h-8 w-8 text-super"
                        />
                        Esci
                      </button>
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}

export default Profile
