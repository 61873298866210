import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { getApiToken } from "../../../services/auth"
import Loading from "../../Loading"
import Seo from "../../seo"
import Table from "../Table"
import EditModal from "../EditModal"
import { toast, ToastContainer } from "react-toastify"
import InsertModale from "../InsertModale"
import defaultImage from "../../../images/default.png"
const Programmi = () => {
  
  const [programmi, setProgrammi] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const [modalEdit, setModalEdit] = useState(false)
  const [edit, setEdit] = useState([])
  const [modal, setModal] = useState(false)
  const handleEdit = row => {
    setEdit(row)
    setModalEdit(true)
  }
  const [values, setValues] = useState({
    name: "",
    enabled: "",
    platform_id: "",
    logo: "",
  })
  const handleSubmit = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const url = process.env.CLICK_API_PROGRAMS
    try {
      await axios({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          name: values.name,
          enabled: parseInt(values.enabled),
          platform_id: parseInt(values.platform_id),
          logo: values.logo,
        },
      })
      getProgrammi()
      setModal(false)
      toast.success("Programma inserito con successo")
    } catch (error) {
      setError(error)
      toast.error("Errore nell'inserimento del programma")
    }
  }
  useEffect(() => {
    getProgrammi()
    // eslint-disable-next-line
  }, [])
  const getProgrammi = async () => {
    const token = await getApiToken()
    const url = process.env.CLICK_API_PROGRAMS
    try {
      const response = await axios({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { type: "form" },
      })
      setProgrammi(response.data)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }
  const { programs, platforms } = programmi
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Attivo",
        accessor: "enabled",
      },
      {
        Header: "Logo",
        accessor: row => {
          return (
            <div className="flex justify-center">
              <img
                src={
                  row.logo
                    ? `https://clicknpay.it/static-images/programs/${row.logo}`
                    : defaultImage
                }
                alt="logo"
                className="w-16 h-auto"
              />
            </div>
          )
        },
      },
      {
        Header: "Piattaforma",
        accessor: "platform.name",
      },
      {
        Header: "Modificare",
        accessor: "action",
        Cell: row => (
          <div className="flex justify-center">
            <button
              className="flex bg-slate-600 text-white py-2 px-3 rounded-md hover:bg-slate-800  hover:text-white transition-colors duration-200"
              onClick={e => handleEdit(row.row.original)}
            >
              Modifica
            </button>
          </div>
        ),
      },
    ],
    []
  )
  const data = useMemo(() => programs, [programs])

  const handleChange = e => {
    e.preventDefault()
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdate = async e => {
    e.preventDefault()
    const token = await getApiToken()
    const dataToSend = {
      name: e.target.name.value,
      enabled: parseInt(e.target.enabled.value),
      platform_id: parseInt(e.target.platform_id.value),
      logo: e.target.logo.value,
    }
    const url = `${process.env.CLICK_API_PROGRAMS}/${edit.id}`
    try {
      await axios({
        url,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataToSend,
      })
      setModalEdit(false)
      getProgrammi()
      toast.success("Programma aggiornato con successo")
    } catch (error) {
      setError(error)
      toast.error("Errore nell'aggiornamento del programma")
    }
  }

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Programmi" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {!isLoaded ? (
        <Loading />
      ) : (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <h2 className="text-gray-700 text-2xl font-medium text-center md:text-left uppercase">
            Lista programmi
          </h2>
          <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          <div>
            <button className="super-btn" onClick={() => setModal(true)}>
              Inserisci
            </button>
            <InsertModale
              modal={modal}
              setModal={setModal}
              title={"Inserisci Dati"}
            >
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={handleSubmit}>
                  <div className="shadow-md overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-3">
                          <label
                            htmlFor="name"
                            className="form-input-label"
                          >
                            Nome
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-input"
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="enabled"
                            className="form-input-label"
                          >
                            Attivo
                          </label>
                          <select
                            id="enabled"
                            name="enabled"
                            onChange={handleChange}
                            className="form-input"
                          >
                            <option value="">--</option>
                            <option value={1}>Si</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                        <div className="col-span-3">
                          <label
                            htmlFor="platform_id"
                            className="form-input-label"
                          >
                            Piattaforma
                          </label>
                          <select
                            id="platform_id"
                            name="platform_id"
                            onChange={handleChange}
                            className="form-input"
                          >
                            <option value="">--</option>
                            {platforms.map(plat => (
                              <option key={plat.id} value={plat.id}>
                                {plat.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-span-3">
                          <label
                            htmlFor="logo"
                            className="form-input-label"
                          >
                            Logo
                          </label>
                          <input
                            id="logo"
                            name="logo"
                            type="text"
                            onChange={handleChange}
                            className="form-input"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          onClick={() => {
                            setModal(false)
                          }}
                          type="submit"
                          className="form-insert-button"
                        >
                          Aggiungi
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </InsertModale>
          </div>
          <EditModal
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            title="Modifica Categoria"
          >
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleUpdate}>
                <div className="shadow-md overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label
                          className="form-input-label"
                          htmlFor="name"
                        >
                          Nome
                        </label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          onChange={handleChange}
                          defaultValue={edit.name}
                          className="form-input"
                        />
                      </div>
                      <div className="col-span-3">
                        <label
                          className="form-input-label"
                          htmlFor="enabled"
                        >
                          Attivo
                        </label>
                        <select
                          id="enabled"
                          name="enabled"
                          onChange={handleChange}
                          defaultValue={edit.enabled}
                          className="form-input"
                        >
                          <option value={1}>Si</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          className="form-input-label"
                          htmlFor="platform_id"
                        >
                          Piattaforma
                        </label>
                        <select
                          id="platform_id"
                          name="platform_id"
                          onChange={handleChange}
                          defaultValue={edit.platform_id}
                          className="form-input"
                        >
                          {platforms.map(plat => (
                            <option key={plat.id} value={plat.id}>
                              {plat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label
                          className="form-input-label"
                          htmlFor="logo"
                        >
                          Logo
                        </label>
                        <input
                          id="logo"
                          name="logo"
                          type="text"
                          onChange={handleChange}
                          defaultValue={edit.logo}
                          className="mt-1 focus:ring-amber-400 focus:border-amber-400 block w-full shadow-sm sm:text-sm rounded-md file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold hover:file:bg-amber-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      onClick={() => setModalEdit(false)}
                      type="submit"
                      className="form-edit-button"
                    >
                      Modifica
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </EditModal>
          <Table columns={columns} data={data} />
        </>
      )}
    </section>
  )
}

export default Programmi
