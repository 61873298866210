import React, { useEffect, useState } from "react"
import { getApiToken, useAuth0 } from "../../services/auth"
import Seo from "../../components/seo"
import ChartPro from "../Chart"
import moment from "moment"
import "moment/locale/it"
import { Link } from "gatsby"
import Loading from "../Loading"
import axios from "axios"

const Riepilogo = () => {
  const { user, error: errorAuth } = useAuth0()
  const [userPoints, setUserPoints] = useState([])
  const [pointsNotRedeemed, setPointsNotRedeemed] = useState(false)
  const [pointsNotRedeemedApproved, setPointsNotRedeemedApproved] = useState(false)
  const [pointsNotRedeemedPending, setPointsNotRedeemedPending] = useState(false)
  const [pointsNotRedeemedExp, setPointsNotRedeemedExp] = useState(false)
  const [surveyDone, setSurveyDone] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState()
  const handleSurvey = () => {
    window.open(`https://docs.google.com/forms/d/e/1FAIpQLSdhm07rGhqG3KpqgENX6R2nEGN-o0TxJWYb-KL8YMgPHC0a2A/viewform?usp=pp_url&entry.239287589=${user.email}`, 'cnpsurvey')
    setSurveyDone(true)
    return;
  }
  const fetchUserPoints = async () => {
    const token = await getApiToken()
    try {
      const response = await axios.get(`${process.env.CLICK_API_POINTS}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setUserPoints(response.data.points)
      setPointsNotRedeemedApproved(response.data.countApproved)
      setPointsNotRedeemedPending(response.data.countPending)
      setPointsNotRedeemedExp(response.data.countExp)
      setPointsNotRedeemed(response.data.countExp >= riscattaPonti ? response.data.countExp : response.data.countApproved)
      setSurveyDone(!!response.data.surveyDone)
      setIsLoaded(true)
    } catch (error) {
      setError(error)
    }
  }
  useEffect(() => {
    fetchUserPoints()
    // eslint-disable-next-line
  }, [])
  const andamenti = userPoints?.reduce((month, { data, points_user }) => {
    if (!month[data]) month[data] = []
    month[data].push(points_user)
    return month
  }, {})
  let riscattaPonti = process.env.RISCATTARE_POINTS
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 my-8">
      <Seo title="Riepilogo" />
      {error && (
        <div className="px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md ">
          <p className="text-center text-gray-900 uppercase tracking-wider">
            {error.message}
          </p>
        </div>
      )}
      {
      errorAuth ? 
      <></>
      :
      !isLoaded ? (
        <Loading />
      ) : (
        <div>
        {!surveyDone && pointsNotRedeemed >= 50 && 
        <div className="px-4 py-5 sm:px-6 bg-amber-50 my-3 border border-amber-100 shadow-md rounded-md ">
          <p className="text-xlg leading-6 font-medium text-gray-900 text-center my-2">
          👋 Come va?
          </p>
          <div className="flex flex-col lg:flex-row justify-around">
          <p className="text-sm lg:text-base text-gray-900 my-2">
          Ci farebbe piacere sentire la tua opinione!<br/> Clicca sul pulsante per accedere ad un breve 
          questionario, al termine del quale ti riconosceremo 50 punti!
          </p>
          <p className="text-center my-2">
            <button className="super-btn" onClick={handleSurvey}>Vai al sondaggio</button>
          </p>
          </div>
        </div>
        } 
          <div className="flex justify-between flex-col gap-4 lg:flex-row">
          <h2 className="text-gray-700 text-2xl font-medium text-center lg:text-left uppercase">
            Benvenuto {user?.given_name}
          </h2>
          <Link className="super-btn" to="/#services">Vai ai servizi</Link>
          </div>
          <div className="my-8 h-2 bg-slate-500 border rounded-full" />
          <div className="px-4 py-5 sm:px-6 text-center bg-amber-50 my-3 border border-amber-100 shadow-md rounded-md">
            <h3 className="text-base md:text-lg leading-6 font-medium text-gray-900 uppercase flex justify-between flex-col lg:flex-row">
              <span>Andamento mensile</span>
              <hr className="block lg:hidden my-2" />
              <span>Distribuzione vendite</span>
            </h3>
          </div>
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 lg:max-w-7xl lg:grid-cols-2">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 flex justify-center lg:justify-start">
              <div className="py-2 lg:py-5 align-middle inline-block  sm:px-6 lg:px-8">
                <div className="shadow lg:shadow-lg overflow-hidden border border-gray-100 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-5 text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider"
                        >
                          Mese
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-5 text-left  text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider border-x"
                        >
                          Totale pratiche definite
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-5 text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider"
                        >
                          Totale punti
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {Object.keys(andamenti)
                        .map(key => {
                          return (
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50"
                              key={key}
                            >
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-gray-900 capitalize">
                                    {moment(key).format("MMMM")}
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-x">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-sky-800">
                                    {andamenti[key].length}
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-sky-800">
                                    {andamenti[key].reduce(
                                      (acc, cur) => acc + cur,
                                      0
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                        .sort((a, b) => {
                          return moment(a.key).diff(moment(b.key))
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="shadow lg:shadow-lg overflow-hidden my-3 border border-gray-100 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-5 text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider border-r"
                        >
                          Stato punti
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-5 text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider"
                        >
                          Totale punti
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50"
                            >
                              <td className="px-6 py-4 whitespace-no-wrap border-r">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-gray-900 capitalize">
                                    In attesa di approvazione
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-sky-800">
                                    {pointsNotRedeemedPending}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50"
                            >
                              <td className="px-6 py-4 whitespace-no-wrap border-r">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-gray-900 capitalize">
                                    Approvati
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-sky-800">
                                    {pointsNotRedeemedApproved}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50"
                            >
                              <td className="px-6 py-4 whitespace-no-wrap border-r">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-gray-900 capitalize">
                                    In scadenza
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="flex items-center">
                                  <div className="text-sm font-medium text-sky-800">
                                    {pointsNotRedeemedExp}
                                  </div>
                                </div>
                              </td>
                            </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className={`${
                    pointsNotRedeemed >= riscattaPonti
                      ? "px-4 py-5 sm:px-6 bg-green-100  my-3 border border-green-200 shadow-md rounded-md "
                      : "px-4 py-5 sm:px-6 bg-red-100 my-3 border border-red-200 shadow-md rounded-md "
                  }`}
                >
                  {pointsNotRedeemed >= riscattaPonti ? (
                    <p className="text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider">
                      Totale punti non riscattati:{" "}
                      <span className="font-bold">{pointsNotRedeemed}</span>,
                      puoi ora riscattare il tuo benefit{" "}
                      <Link
                        to="/account/profile/riscatta"
                        className="text-green-700 font-bold hover:underline cursor-pointer"
                      >
                        QUI
                      </Link>
                    </p>
                  ) : (
                    <p className="text-left text-xs font-normal md:text-sm md:font-medium text-gray-900 uppercase tracking-wider">
                      Totale punti non riscattati:{" "}
                      <span className="font-bold">{pointsNotRedeemed}</span>,
                      ottieni altri {riscattaPonti - pointsNotRedeemed} per
                      poter riscattare il tuo benefit
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <ChartPro userPoint={userPoints} />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Riepilogo
